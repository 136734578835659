import React, {lazy, useEffect, useState} from 'react'
import dayjs, {Dayjs} from "dayjs";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";

import {TablePagination} from "@mui/material";

import {
  listenWhereFireStore,
  paginateFireStore,
  Query,
  queryFireStore,
  queryNestedFireStore,
  queryNestedFireStoreDoc,
  readRealtime
} from "../../hooks/firebase";

import {IChatMeta} from "../../types/chat";
import {useDoc, useQueryDocs, useQueryPagination} from "../../hooks/firestore";
import {chat} from "googleapis/build/src/apis/chat";
import {useAuth} from "../../hooks/auth";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {getDocs} from "firebase/firestore";
import Icon from "@mui/material/Icon";

import UnreadIcon from "@mui/icons-material/MarkChatUnread"
import MessageIcon from '@mui/icons-material/Message';
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Tabs from "@mui/material/Tabs";
import ReservationModal from "../../partials/ReservationModal";
import ButtonBase from "@mui/material/ButtonBase";
import {Team} from "../../models/Operation";
import {queries} from "@testing-library/react";

// const ALL = 'ALL'
const CS_CATEGORIES = ['ALL', 'KUP', 'CS'];


export default function CS() {
  const [reservationId, setReservationId] = useState<string | null>(null);
  const [chatCategory, setChatCategory] = useState<string>(CS_CATEGORIES[0]);

  function handleTabChange(_: any, value: string) {
    setChatCategory(value)
  }

  return (
    <>
      <Stack px={8}>
        {/*<Box>*/}
        {/*    <Tabs onChange={handleChangeAreaTab} value={areaTab}>*/}
        {/*        {*/}
        {/*            AREA_TABS.map((t) => {*/}
        {/*                return <Tab value={t} label={t} key={t} id={t}/>*/}
        {/*            })*/}
        {/*        }*/}
        {/*    </Tabs>*/}
        {/*</Box>*/}
        <TabContext value={chatCategory}>
          <TabList onChange={handleTabChange}>
            {CS_CATEGORIES.map((key) => {
              return <Tab key={key} label={key} value={key}/>
            })}
          </TabList>

          <TabPanel value={CS_CATEGORIES[0]}>
            <ChatListDisplay
              queries={[['category', '>=', 'CS'], ['category', '<', 'CS' + '\uf8ff']]} unreadFirst={false}
              onClickReservation={(id: string) => setReservationId(id)}
            />
          </TabPanel>
          <TabPanel value={CS_CATEGORIES[1]}>
            <ChatListDisplay queries={[['category', '==', 'CS-KUP']]} unreadFirst={true}
                             onClickReservation={(id: string) => setReservationId(id)}
            />
          </TabPanel>
          <TabPanel value={CS_CATEGORIES[2]}>
            <ChatListDisplay queries={[['category', '==', 'CS']]} unreadFirst={true}
                             onClickReservation={(id: string) => setReservationId(id)}
            />
          </TabPanel>
          {/*</TabPanel>*/}
          {/*<TabPanel value={CS_CATEGORIES[1]}>*/}
          {/*  <ChatListDisplay queries={[['category', '==', 'CS-PROMOTION']]} unreadFirst={true}*/}
          {/*                   onClickReservation={(id: string) => setReservationId(id)}*/}
          {/*  />*/}
          {/*</TabPanel>*/}
        </TabContext>
      </Stack>

      {
        reservationId
          ? <ReservationModal
            disabled
            reservationId={reservationId}
            onCopy={(reservation) => {
            }}
            onClose={(update) => {
              setReservationId(null);
            }}
          />
          : null
      }
    </>
  )
}

function ChatListDisplay(props: {
  queries: Query[],
  unreadFirst: boolean,
  onClickReservation: (reservationId: string) => void
}) {
  const [unread, setUnread] = useState<boolean>(props.unreadFirst);
  return (
    <Box>
      <FormControlLabel
        control={<Switch
          defaultChecked={unread}
          checked={unread}
          onChange={(_: any, value) => setUnread(value)}
        />}

        label="Only Unread(live)"
      />
      {unread ? (<UnreadChatlist queries={props.queries} onClickReservation={props.onClickReservation}/>) :
        <StaticChatTable queries={props.queries} onClickReservation={props.onClickReservation}/>}
    </Box>
  )
}


function UnreadChatlist(props: { queries: Query[], onClickReservation: (reservationId: string) => void }) {
  const {operator} = useAuth();
  const [unreadChatIds, setunreadChatIds] = useState<string[]>([]);
  const [chats, setChats] = useState<IChatMeta[]>([]);
  useEffect(() => {
    const uid = operator?.id;
    const unsub = queryNestedFireStoreDoc('messages', [[`readStatus.${uid}}`, '==', false]], (s) => {
      const unreadChatIds = [...new Set<string>(s.docs.map(d => d.ref.parent.parent?.id ?? '').filter(id => id.length > 0)).values()].sort((a, b) => a > b ? 1 : 0)
      setunreadChatIds(unreadChatIds)
    })
    return () => {
      unsub();
    }
  }, [operator?.id])

  useEffect(() => {
    const unsubs: any[] = [];
    if (unreadChatIds.length > 0) {
      queryFireStore<IChatMeta>('chats', [['id', 'in', unreadChatIds], ...props.queries]).then((chats) => {
        setChats(chats)
      })
    } else {
      setChats([]);
    }
    return () => {
      unsubs.forEach(unsub => unsub());
    }
  }, [unreadChatIds.toString(), JSON.stringify(props.queries)])

  return (
    <TableContainer component={Card}>
      <Table aria-label="collapsibnle table">
        <TableHead>
          <TableCell>상태</TableCell>
          <TableCell>업데이트 일시</TableCell>
          <TableCell>참여자(예약)</TableCell>
          <TableCell>가이드-투어</TableCell>
          <TableCell>CS 마지막 확인일</TableCell>
          <TableCell>마지막 메세지</TableCell>
          <TableCell>마지막 확인일</TableCell>
        </TableHead>
        <TableBody>
          {chats
            .sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)
            .map((row) => (
            <ChatRow key={row.id} chat={row} onClickReservation={props.onClickReservation}/>))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}


function StaticChatTable(props: { queries: Query[], onClickReservation: (reservationid: string) => void }) {
  const {
    data: chats,
    loading,
    total,
    page,
    rowsPerPage,
    handlePagination,
    handleRowsPerPage,
  } = useQueryPagination<IChatMeta>({
    pause: false,
    paths: ["chats"],
    count: 100,
    queries: props.queries,
    order: {
      field: 'updatedAt',
      type: "desc"
    },
  })


  return (

    <TableContainer component={Card}>
      <Table aria-label="collapsibnle table">
        <TableHead>
          <TableCell>상태</TableCell>
          <TableCell>업데이트 일시</TableCell>
          <TableCell>참여자(예약)</TableCell>
          <TableCell>가이드-투어</TableCell>
          <TableCell>마지막 메세지</TableCell>
          <TableCell>업데이트일</TableCell>
          <TableCell>CS 마지막 확인일</TableCell>
          <TableCell>내 마지막 확인일</TableCell>
        </TableHead>
        <TableBody>
          {chats
            .sort((a, b) => a.updatedAt > b.updatedAt ? -1 : 1)
            .map((row) => (
              <ChatRow key={row.id} chat={row} onClickReservation={props.onClickReservation}/>))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handlePagination}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPage}
      />
    </TableContainer>
  )
}

function ChatRow(props: { chat: IChatMeta, onClickReservation: (reservationId: string) => void }) {
  const {operator} = useAuth();
  const {chat} = props;
  const uid = operator?.id;
  const {data: unreadMessages} = useQueryDocs(`chats/${chat.id}/messages`, [[`readStatus.${uid}`, '==', false]], chat.id);
  const [team, setTeam] = useState<any>(null);
  const [product, setProduct] = useState<any>(null);
  useEffect(() => {
    if (!chat?.tour?.date || !(chat?.tour?.tourId || chat?.tour?.productId) || !chat?.tour?.team) return;
    readRealtime<Team>(
      `/operation/${chat.tour.date}/tours/${chat.tour.tourId ?? chat.tour.productId}/teams/${chat.tour.team}`
    ).then((_operationTeam) => {
      if (setTeam)
        setTeam(_operationTeam)
    })
    readRealtime<any>(
      `/product/${chat.tour.tourId ?? chat.tour.productId}`
    ).then((_product) => {
      if (setProduct)
        setProduct(_product);
    })
  }, [chat?.tour?.date, chat?.tour?.tourId, chat?.tour?.productId, chat?.tour?.team,])


  const label = `${team?.guides?.map(({name}: {
    name: string
  }) => name).join('/')}(${product?.name},${chat?.tour?.date})`;

  const highlight = (chat.lastMessage?.type !== 'exit') && ((chat.lastMessage?.count ?? 2) > 2 || dayjs(chat.lastMessage?.date.toDate()).diff(dayjs(chat.createdAt.toDate()), "seconds") > 10);


  return (
    <TableRow sx={{
      backgroundColor: highlight ? '#f0faff' : 'inherit'
    }}>
      <TableCell>
        <IconButton
          onClick={() => {
            window.open(`/chat/rooms/${chat.id}`, "_blank", "width=420,height=700")
          }}
        >
          {
            unreadMessages.length > 0
              ? <Icon color={'primary'}><UnreadIcon/></Icon>
              : <Icon color={'inherit'}><MessageIcon/></Icon>
          }
        </IconButton>
      </TableCell>
      <TableCell>{chat.updatedAt.toDate().toLocaleString()}</TableCell>
      <TableCell>{chat.category}</TableCell>
      <TableCell>
        <ButtonBase
          onClick={() => {
            const reservationId = Object.values(chat.participants).filter((p) => p.type.toLowerCase() === 'client')[0]?.id
            props.onClickReservation(reservationId);
          }}
        >
          {
            (() => {
              const participantsWOMe = Object.values(chat.participants).filter(p => p.type?.toLowerCase() !== 'operator').map(p => p.name)
              return participantsWOMe.join(', ') + (participantsWOMe.length > 2 ? `외 ${participantsWOMe.length - 2}인` : '')
            })()
          }
        </ButtonBase>
      </TableCell>
      <TableCell>{label}</TableCell>
      <TableCell sx={{
        width: '30%',
        maxWidth: '30%'
      }}>
        <a
          style={{color: "inherit"}}
          href={`javascript:window.open(\`/chat/rooms/${chat.id}\`, "_blank", "width=420,height=700")`}
        >
          {chat.lastMessage?.text?.slice(0, 30) ?? 'file'}

        </a>
      </TableCell>
      <TableCell>{chat.updatedAt.toDate().toLocaleString()}</TableCell>
      <TableCell>{chat.readDate?.[operator?.id ?? '']}</TableCell>
      <TableCell>{chat.readDate?.[operator?.id ?? ''] ?? ''}</TableCell>
    </TableRow>
  )
}